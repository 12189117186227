import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import Logo from '../images/umbrella-logo-horizontal.svg'

import useSiteMetadata from '../hooks/useSiteMetadata'

const Header = ({ className, showTitle }) => {
  const { title } = useSiteMetadata()

  return (
    <div
      tw="md:flex text-center sm:text-right items-center justify-center md:justify-between w-full mb-2 lg:mb-8"
      className={className}
    >
      <a tw="inline-block w-2/3 sm:w-2/3 md:w-1/2 max-w-xs mb-4 md:m-0" href="https://umbrellafamily.com">
        <Logo title={title} />
      </a>
      {showTitle && <h1 tw="text-purple font-bold text-lg md:text-xl lg:text-2xl md:ml-4">Child Care Requirements</h1>}
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  showTitle: PropTypes.bool,
}

// Header.defaultProps = {
//   siteTitle: ``,
//   pathname: `/`,
// }

export default Header
