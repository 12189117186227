// @flow
import React from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'

const Footer = ({ className }) => (
  <footer tw="mx-auto text-xs text-center py-4 lg:px-4" className={className}>
    <div>
      <p tw="mb-2">
        Your data will be saved, see{' '}
        <a tw="underline" href="https://www.umbrellafamily.com/privacy/" target="_blank" rel="noreferrer">
          Privacy Statement
        </a>
      </p>
      <p>
        © {new Date().getFullYear()} {` `}
        <a tw="underline" href="https://umbrellafamily.com">
          <span tw="whitespace-nowrap">Umbrella Family and Child Centres of Hamilton</span>
        </a>
      </p>
    </div>
  </footer>
)

export default Footer
