import tw, { styled, css } from 'twin.macro'

// STYLED COMPONENTS

export const FlexContainer = styled.div`
  ${tw`w-screen h-screen flex flex-col content-start py-6 px-4 md:p-8`}
`

export const Container = styled.div`
  ${tw`max-w-screen-md w-full mx-auto`}
`

export const BgSlanted = styled.div`
  ${tw`relative w-screen max-w-5xl bg-white pb-8`}
  clip-path: polygon(-1% -1%, 100% -1%, 100% 89%, -1% 100%);

  ${({ theme }) => theme.mq('lg')} {
    ${tw`pb-0 w-auto h-screen`}
    clip-path: polygon(0% 0%, 100% 0%, 89% 100%, 0% 100%);
  }
`

export const FullscreenBackgroundWrapper = styled.div`
  ${tw`pointer-events-none fixed `}
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;

  &:after {
    content: '';
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(102, 48, 117, 0) 80%, rgb(102, 48, 117) 100%);
    ${({ theme }) => theme.mq('lg')} {
      background: linear-gradient(130deg, rgba(102, 48, 117, 0) 50%, rgb(102, 48, 117) 100%);
    }
  }
`

export const ProgramCard = styled.div`
  ${tw`relative w-auto md:w-1/3 bg-white p-3 rounded shadow-md`}

  &:after {
    left: 10%;
    bottom: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: ${({ theme }) => theme.colors.white};
    border-width: 14px;
    margin-left: -14px;
    clip-path: polygon(50% 10%, -25% 100%, 125% 100%);
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 2px 4px rgba(0, 0, 0, 0.06));
  }

  ${({ theme }) => theme.mq('md')} {
    &:after {
      left: initial;
      right: 100%;
      top: 50%;
      bottom: initial;
      margin-top: -14px;
      border-bottom-color: transparent;
      border-right-color: ${({ theme }) => theme.colors.white};
      clip-path: polygon(-5% 50%, 100% 150%, 100% -50%);
    }
  }
`
