import React from 'react'
import tw, { styled, css } from 'twin.macro'

export const FormSection = styled.div`
  ${tw`p-4 sm:p-6`}
`

export const FormHeader = styled(FormSection)`
  ${tw`text-center`}

  h1 {
    ${tw`text-lg md:text-2xl lg:text-4xl font-bold text-purple text-center mb-2 md:mb-4`}
  }

  h2 {
    ${tw`text-base md:text-xl lg:text-2xl font-bold text-purple text-center mb-4`}
  }

  /* p {
    ${tw`text-sm`}
  } */
`

export const FormContainer = styled.div`
  ${tw`border-solid border-t-2 border-b-2 border-purple-lightest bg-white divide-y divide-gray-200 sm:shadow-xl`}
`

export const FormFooter = styled(FormSection)`
  ${tw`flex justify-center sm:justify-end items-center lg:pt-8`}
`

export const FormControl = styled.div`
  ${tw`my-3 relative`}
`

export const Row = styled.div`
  ${tw`flex-wrap sm:flex-nowrap`}
`

export const InputError = styled.div`
  ${tw`text-red text-xs font-bold block relative pt-2`}
`

export const label = tw`block text-sm font-semibold mb-2`

export const input = css`
  ${tw`h-12 bg-white w-full px-4 border border-gray-200 rounded-md shadow-sm appearance-none`}
  line-height: 1.2em;

  &:disabled {
    ${tw`cursor-not-allowed bg-gray-100`}
  }
`

export const Select = ({ children, ...props }) => (
  // console.log('Select Props', props)
  <div tw="relative">
    <select css={input} tw="pr-8" {...props}>
      {children}
    </select>
    <div tw="absolute flex inset-y-0 items-center px-3 right-0 text-gray-300 rounded-r pointer-events-none">
      <svg tw="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </div>
)

const hiddenClip = css`
  clip-path: polygon(0 0);
`

const checkStyle = css`
  &:after {
    content: 'test';
    position: absolute;
    top: -3px;
    left: 0;
  }
`

export const styledCheckbox = css`
  width: 1.25rem;
  height: 1.25rem;
`

// export const styledCheckbox = css`
//   display: inline-flex;
//   cursor: pointer;
//   position: relative;

//   > span {
//     color: #34495e;
//     padding: 0.5rem 0.25rem;
//   }

//   > input {
//     ${tw`h-4 w-4 bg-white border border-gray-200 rounded-md shadow-sm appearance-none outline-none cursor-pointer`}

//     transition-duration: 0.3s;
//   }

//   > input:checked {
//     border: 1px solid #41b883;
//     background-color: #34495e;
//   }

//   > input:checked + span::before {
//     content: '\2713';
//     display: block;
//     text-align: center;
//     color: #41b883;
//     position: absolute;
//     left: 0.7rem;
//     top: 0.2rem;
//   }

//   > input:active {
//     border: 2px solid #34495e;
//   }
// `

export const Checkbox = ({
  id,
  field: { name, value },
  form: { errors, setFieldTouched, setFieldValue },
  labelText,
  children,
  ...rest
}) => {
  console.log('Select Props', value, rest)
  const handleChange = ({ target }) => {
    target.value = !!target.value
    console.log('checkbox values', target)
    // setFieldTouched(name)
    setFieldValue(name, target.value)
  }
  return (
    <label css={label} tw="flex" htmlFor={id}>
      <span className="ant-checkbox">
        <input type="checkbox" id={id} value={value} name={name} onChange={handleChange} {...rest} />
        <span className="ant-checkbox-inner" tw="absolute cursor-pointer w-4 h-4 bg-purple left-0 rounded " />
      </span>
      <span tw="ml-3">{labelText}</span>
      {children}
    </label>
  )
}

export const Radio = props => (
  // console.log('Radio Props', props)
  <div tw="relative">
    <input type="radio" css={input} {...props} />
  </div>
)

export const listBox = tw`
  absolute w-full -mt-3 bg-white z-10 border border-gray-200 shadow-sm rounded-b-md
`

export const listItem = tw`
  text-sm hover:bg-gray-100 py-1 px-3 hover:text-purple
`

export const listItemDarken = tw`
  bg-gray-100 text-purple
`

export const googleLogo = css`
  ${tw`px-3 py-2 w-full text-right`}
  img {
    ${tw`inline max-w-xs w-1/6`}
  }
`
